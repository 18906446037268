import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/components/Layout/Layout.js";
import { Link } from 'gatsby';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCaretUp } from '@fortawesome/free-solid-svg-icons';
import ExternalLink from '../components/ExternalLink/ExternalLink';
import SEO from '../components/SEO/seo';
import TransitionLink from '../components/TransitionLink/TransitionLink';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">




    <SEO title="Now" mdxType="SEO" />
    <h1>{`Now`}</h1>
    <p>{`This is what I'm up to nowadays.`}</p>
    <h2>{`Work`}</h2>
    <p>{`I am a frontend engineer at `}<ExternalLink href="https://www.crunchbase.com" text="Crunchbase" mdxType="ExternalLink" />{`, an all-in-one prospecting tool with private-company data. Check out Crunchbase to find the right company and to find and engage with the right person.`}</p>
    <p>{`I am taking a break this year from my small business, where I typically handcraft tabletop items like coasters and decorative trays with Jesmonite, an eco-friendly material made from gypsum. As such, I have also paused updates to my online shop, but you can typically shop in person in NYC at `}<ExternalLink href="https://www.essexmarket.nyc/the-pushcart-presents" text="The Pushcart Presents at Essex Market" wrap mdxType="ExternalLink" />{`.`}</p>
    <h2>{`Learning`}</h2>
    <p>{`I started taking tennis lessons in the summer of 2022, and I enjoy making new tennis friends in the city. I typically play twice a week, and I am currently using a `}<ExternalLink href="https://www.yonex.com/tennis/racquets/ezone/07ez100" text="Yonex Ezone 100 2022 racket" wrap mdxType="ExternalLink" />{` with a `}<ExternalLink href="https://www.wilson.com/en-us/product/nxt-rz94270" text="Wilson NXT 16 string" wrap mdxType="ExternalLink" />{`.`}</p>
    {
      /* I also like developing my craft as a software engineer by reviewing, practicing, and learning computer science concepts. I am currently practicing sorting and recursion, and I would like to work on system design and UI system design later on. */
    }
    {
      /* ## Projects */
    }
    <h2>{`Reading`}</h2>
    <p>{`You can see my reading list `}<TransitionLink to="/reading" mdxType="TransitionLink">{`here`}</TransitionLink>{`.`}</p>
    <h2>{`Last updated`}</h2>
    <p>{`May 13th, 2023 from New York City.`}</p>
    <p>{`This page was inspired by Derek Sivers and the `}<ExternalLink href="https://www.nownownow.com/about" text="NowNowNow" mdxType="ExternalLink" />{` movement.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      